import { useEffect, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import ProtectedRoute from './utils/ProtectedRoute';
import Profile from './pages/Profile/Profile';
import Login from './pages/Login/Login';
import api from './utils/Api';
import Header from './components/Header/Header';
import Loading from './components/LoadingComponent/LoadingComponent';
import { AnimatePresence } from 'framer-motion';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [incidentsList, setIncidentsList] = useState([]);
  const [isPopupOpened, setPopupOpened] = useState(false);
  const [loginError, setLoginError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      fetchUser(token);
    } else {
      setIsInitialLoading(false);
    }
  }, []);

  const onSignIn = async ({ username, password }) => {
    try {
      setIsInitialLoading(true);
      const token = await api.LoginAD({ username, password });
      localStorage.setItem('jwt', token.Token);
      await fetchUser(token.Token);
      setIsLoggedIn(true);
      fetchIncidents();
    } catch (error) {
      setLoginError('Неверный логин или пароль');
      setIsLoggedIn(false);
      localStorage.clear();
    } finally {
      setIsInitialLoading(false);
    }
  };

  const fetchIncidents = async () => {
    try {
      const token = localStorage.getItem('jwt');
      const iniciatorUuid = localStorage.getItem('currentIniciatorUuid');
      if (token && iniciatorUuid) {
        const res = await api.getIncidents(token, iniciatorUuid);
        setIncidentsList(res.Incidents);
      }
    } catch (error) {
      console.error(error);
      if (error.status >= 501 && error.status <= 509) {
        setLoginError('Соединение с сервером нестабильно');
      } else {
        setLoginError('Произошла ошибка при получении списка инцидентов');
      }
    }
  };

  const onSignOut = () => {
    setIsLoggedIn(false);
    localStorage.clear();
    document.cookie.split(';').forEach(cookie => {
      document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    navigate('/sign-in');
  };

  const fetchUser = async (token) => {
    try {
      const { UTekP, UFiz, UIniciator, Full_name, UClient, Key_user, User_number, User_consumer, User_Web_consumer } = await api.Login(token);
      localStorage.setItem('currentUserUuid', UTekP);
      localStorage.setItem('currentPhysUuid', UFiz);
      localStorage.setItem('currentIniciatorUuid', UIniciator);
      localStorage.setItem('userFullName', Full_name);
      localStorage.setItem('currentClientUuid', UClient);
      localStorage.setItem('isKeyUser', Key_user);
      localStorage.setItem('phone_number', User_number);
      localStorage.setItem('user_consumer', User_consumer);
      localStorage.setItem('user_web_consumer', User_Web_consumer);
      setIsLoggedIn(true);
    } catch (error) {
      console.error(error.message);
      localStorage.clear();
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authToken = params.get('authToken');
    if (authToken && !isLoggedIn) {
      const authorizeWithToken = async (encodedToken) => {
        try {

          localStorage.setItem('jwt', encodedToken);
          await fetchUser(encodedToken);
          setIsLoggedIn(true);
          fetchIncidents();
          navigate('/profile', { replace: true });
        } catch (error) {
          setLoginError('Неверный токен');
          setIsLoggedIn(false);
          localStorage.clear();
          navigate('/sign-in');
        } finally {
          setIsInitialLoading(false);
        }
      };
      authorizeWithToken(authToken);
    }
  }, [location.search]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchIncidents();
      navigate('/profile');
    }
  }, [isLoggedIn]);


  if (isInitialLoading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <Header isLoggedIn={isLoggedIn} onSignOut={onSignOut} setPopupOpened={setPopupOpened} />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path='/sign-in' element={<Login onSignIn={onSignIn} loginError={loginError} />} />
          <Route
            path='/profile'
            element={
              isLoggedIn ? (
                <ProtectedRoute
                  isLoggedIn={isLoggedIn}
                  incidentsList={incidentsList}
                  isPopupOpened={isPopupOpened}
                  setPopupOpened={setPopupOpened}
                  component={Profile}
                  fetchIncidents={fetchIncidents}
                />
              ) : (
                <Navigate to='/sign-in' />
              )
            }
          />
          <Route
            path='/status-test'
            element={isLoggedIn ? <Navigate to='/profile' /> : <Navigate to='/sign-in' />}
          />
          <Route
            path='/params'
            element={<Navigate to='/profile' />}
          />
          <Route path='*' element={isLoggedIn ? <Navigate to='/profile' /> : <Navigate to='/sign-in' />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
