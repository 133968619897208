import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import back from '../../utils/images/back.svg';
import Select from 'react-select';
import FileInput from "../FileInput/FileInput";
import InputMask from 'react-input-mask';
export default function OrgTypeInStep({
    selectedService,
    selectedComponent,
    handlePrevStep,
    setDescription,
    handleSubmit,
    isLoading,
    description,
    setMobilePhone,
    mobilePhone
}) {
    const [isEmpty, setIsEmpty] = useState(false);
    const [filesArray, setFileBase64List] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedPlace, setSelectedPlace] = useState('');
    const [selectedDevice, setSelectedDevice] = useState('');
    const [descriptionTA, setDescriptionTA] = useState('')
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
    const [phoneNumberValue, setPhoneNumberValue] = useState('');
    
    const handlePhoneNumberCheckboxChange = (e) => {
        setIsPhoneNumberValid(e.target.checked);
        setPhoneNumberValue('');
    };

    const handlePhoneNumberInputChange = (e) => {
        setPhoneNumberValue(e.target.value);
        setMobilePhone(e.target.value);
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
        }
        return phoneNumber;
    };




    const handleFormSubmit = (e) => {
        e.preventDefault();
        updateDescription();
        if (!description) {
            setIsEmpty(true);
            return;
        }
        setIsEmpty(false);
        handleSubmit(e, filesArray);
    };

    useEffect(() => {
        setSelectedPlace('');
    }, [selectedCity]);

    const updateDescription = () => {
        const fullDescription =
            `Город: ${selectedCity.label}\nЛокация: ${selectedPlace.label}\nТип устройства: ${selectedDevice.label}\n${descriptionTA ? descriptionTA : ''}`;
        setDescription(fullDescription);
    };

    useEffect(() => {
        updateDescription();
    }, [selectedCity, selectedPlace, selectedDevice, descriptionTA]);

    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            width: 'auto',
            fontFamily: 'Circe',
            height: '40px',
            zIndex: '20',
            borderRadius: '10px',
            borderColor: '#B3B3B3',
            textAlign: 'left',
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                border: state.isFocused ? '1px solid #0082C8' : '1px solid #B3B3B3'
            },
            opacity: state.isDisabled ? .5 : 1,
            backgroundColor: state.isDisabled ? '#fff' : '#fff',
        }),
        option: (baseStyles) => ({
            ...baseStyles,
            borderRadius: '10px'
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            fontFamily: 'Circe',
            height: '38px',
            paddingLeft: '10px'
        }),
        container: (baseStyles) => ({
            ...baseStyles,
            fontFamily: 'Circe',
            width: '100%',
            height: '40px'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: '15px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontWeight: 'normal',
            height: '40px',
            marginBottom: '5px',
            color: '#000',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: '15px',
            fontFamily: 'Circe',
            height: '38px',
            marginBottom: '6px',
            borderRadius: '10px',
            color: '#000',
        }),
        menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999,
            textAlign: 'left'
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            padding: '0px',
            borderRadius: '10px',
            textAlign: 'left',
            fontFamily: 'Circe',
            fontSize: '15px',
            borderColor: '#B3B3B3',
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '0',
        }),
    };

    const devices = [
        { value: 'МФУ ', label: 'МФУ' },
        { value: 'Плоттер', label: 'Плоттер' },
        { value: 'Расходные материалы', label: 'Расходные материалы' },
        { value: 'Другое', label: 'Другое' },
    ];
    const cities = [
        { value: 'Москва', label: 'Москва' },
        { value: 'Санкт-Петербург', label: 'Санкт-Петербург' },
        { value: 'Казань', label: 'Казань' },
        { value: 'Красноярск', label: 'Красноярск' },
        { value: 'Горно-Алтайск', label: 'Горно-Алтайск' }
    ];
    

    const placesByCity = {
        'Москва': [
            { value: 'Multipsace Павелецкая', label: 'Multipsace Павелецкая' },
            { value: 'Multispace Динамо', label: 'Multispace Динамо' },
            { value: 'СБЕР А ', label: 'СБЕР А ' },
            { value: 'ПСБ', label: 'ПСБ' },
            { value: 'Балчуг', label: 'Балчуг' },
            { value: 'Тинькофф', label: 'Тинькофф' },
            { value: 'IСity', label: 'IСity' },
            { value: 'Сколково Школа', label: 'Сколково Школа' },
            { value: 'Сколково Мегацод', label: 'Сколково Мегацод' },
            { value: 'Сколково Автобан', label: 'Сколково Автобан' },
            { value: 'Позитив', label: 'Позитив' },
            { value: 'Прорыв', label: 'Прорыв' },
            { value: 'Росатом', label: 'Росатом' },
            { value: 'Центр найма ', label: 'Центр найма ' },
            
        ],
        'Санкт-Петербург': [
            { value: 'Лахта', label: 'Лахта' },
            { value: 'Чкаловская', label: 'Чкаловская' },
            { value: 'Multispace Pravda ', label: 'Multispace Pravda' },
        ],
        'Казань': [
            { value: 'Офис', label: 'Офис' },
        ],
        'Горно-Алтайск': [
            { value: 'Манжерок', label: 'Манжерок' },
        ],
        'Красноярск': [
            { value: 'Красноярск', label: 'РусГидро' },
        ],
    };

    return (
        <>
            <img src={back} className="incident-popup__button-back" onClick={handlePrevStep}></img>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__text-block">
                <p className="incident-popup__paragraph">Выбранная услуга:</p>
                <p className="incident-popup__paragraph" style={{margin: '0'}}>
                    <span>
                        {selectedService?.Service} — {selectedComponent?.ServiceComponent}
                    </span>
                </p>
                {selectedComponent.ServiceDescription ? <p className="incident-popup__subtitle">{selectedComponent?.ServiceDescription}</p> : null}
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="incident-popup__form">
                <form className="incident-popup__form" onSubmit={handleFormSubmit}>
                    <p className="incident-popup__paragraph" style={{margin: '15px 0 15px 0'}}>Ваше местоположение и тип устройства</p>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(80% + 20px)', gap: '15px' }}>
                        <Select
                            isSearchable={false}
                            options={cities}
                            placeholder="Город"
                            styles={selectStyles}
                            required
                            menuPortalTarget={document.body}
                            menuPosition='fixed'
                            onChange={setSelectedCity}
                        />
                        <Select
                            isSearchable={false}
                            options={placesByCity[selectedCity?.value] || []}
                            placeholder="Локация"
                            styles={selectStyles}
                            required
                            menuPortalTarget={document.body}
                            menuPosition='fixed'
                            onChange={setSelectedPlace}
                            value={selectedPlace}
                        />
                        <Select
                            isSearchable={false}
                            options={devices}
                            placeholder="Тип устройства"
                            styles={selectStyles}
                            required
                            menuPortalTarget={document.body}
                            menuPosition='fixed'
                            onChange={setSelectedDevice}
                        />
                    </div>
                    <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="incident-popup__paragraph" style={{ marginTop: '70px', marginBottom: '15px' }}>Описание:</motion.p>
                    <textarea
                        className="incident-popup__textarea"
                        id="description"
                        placeholder="Дополните уточняющей информацией... "
                        onChange={(e) => setDescriptionTA(e.target.value)}
                        required
                        style={{ paddingBottom: '50px' }}
                    ></textarea>
                    <div className="incident-popup__phone-div">
                        <label className="incident-popup__phone__label">
                            Изменить
                            <input className="incident-popup__phone__checkbox" type="checkbox" onChange={handlePhoneNumberCheckboxChange} checked={isPhoneNumberValid}></input>
                        </label>
                        <InputMask className="incident-popup__phone__input"
                            key='input-phone-mask'
                            mask='+7 (999) 999-99-99'
                            maskChar=''
                            guide={false}
                            type="tel"
                            value={phoneNumberValue}
                            placeholder={!isPhoneNumberValid ? formatPhoneNumber(localStorage.getItem('phone_number')) : '+7'}
                            onChange={(e) => handlePhoneNumberInputChange(e)}
                            disabled={!isPhoneNumberValid}
                        />
                        <p>Ваш контактный номер</p>
                    </div>
                    {isEmpty && <p style={{ color: "red" }}>Поле не может быть пустым</p>}
                    <div className="incident-popup__button-div">
                        <FileInput setFilesArray={setFileBase64List} />
                        <button className="incident-popup__button" type="submit" disabled={isLoading || (isPhoneNumberValid && phoneNumberValue.length !== 18) || (!isPhoneNumberValid && phoneNumberValue.length > 0)}>
                            {isLoading ? 'Отправка...' : 'Отправить'}
                        </button>
                    </div>
                </form>
            </motion.div >
        </>
    );
}
